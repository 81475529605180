export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_LESSONS = 'GET_LESSONS';
export const GET_LESSON = 'GET_LESSON';
export const LESSON_ERROR = 'LESSON_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const UPDATE_IN_PROGRESS = 'UPDATE_IN_PROGRESS';
export const UPDATE_COMPLETES = 'UPDATE_COMPLETES';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const DELETE_LESSON = 'DELETE_LESSON';
export const GET_TRACKS = 'GET_TRACKS';
export const GET_TRACK = 'GET_TRACK';
export const DISPLAY_TRACK_LESSONS = 'DISPLAY_TRACK_LESSONS';
export const TRACK_ERROR = 'TRACK_ERROR';
export const DELETE_TRACK = 'DELETE_TRACK';
export const UPDATE_TRACK_COMPLETES = 'UPDATE_TRACK_COMPLETES';
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTING = 'GET_SETTING';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
